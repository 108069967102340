import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ADD_TO_WORKSPACE_INFO_MESSAGE_KEYS, MODE } from '../constants';
import { useAddUsersToWorkspacesMutation } from '../../../views/internal/users/service';

const useAddToWorkspace = ({
  localSelectedItems,
  workspaceName,
  workspaceIds,
  handleAddSuccessCallback,
}) => {
  const intl = useIntl();

  const itemsNotInWorkspace = useMemo(() => {
    return localSelectedItems.filter((item) => item.isNotInWorkspace);
  }, [localSelectedItems]);

  const _formatMessageWithWorkspaceName = useCallback(
    (messageKey) => {
      return intl
        .formatMessage({ id: messageKey })
        .replace(':workspace_name', workspaceName);
    },
    [intl, workspaceName]
  );

  const _formatSecondaryMessageWithUserName = useCallback(
    (messageKey, userName) => {
      return intl
        .formatMessage({ id: messageKey })
        .replace(':user_name', userName);
    },
    [intl]
  );

  const formattedInfoMessages = useMemo(() => {
    const isMultipleUsersSelected = itemsNotInWorkspace.length > 1;
    const isMultipleWorkspacesSelected = workspaceIds.length > 1;
    const keys = isMultipleUsersSelected
      ? ADD_TO_WORKSPACE_INFO_MESSAGE_KEYS[MODE.multi]
      : ADD_TO_WORKSPACE_INFO_MESSAGE_KEYS[MODE.single];

    const messages = {};

    if (isMultipleUsersSelected) {
      messages.message = _formatMessageWithWorkspaceName(keys.messageKey);
      messages.secondaryMessage = intl.formatMessage({
        id: keys.secondaryMessageKey,
      });
    } else {
      const userName = itemsNotInWorkspace[0]?.full_name || '';
      if (isMultipleWorkspacesSelected) {
        messages.message = intl.formatMessage({
          id: 'user_not_part_of_all_workspaces',
        });
        messages.secondaryMessage = _formatSecondaryMessageWithUserName(
          'want_to_add_user_to_multiple_workspaces',
          userName
        );
      } else {
        messages.message = _formatMessageWithWorkspaceName(keys.messageKey);
        messages.secondaryMessage = _formatSecondaryMessageWithUserName(
          keys.secondaryMessageKey,
          userName
        );
      }
    }

    return messages;
  }, [
    _formatMessageWithWorkspaceName,
    intl,
    itemsNotInWorkspace,
    workspaceIds.length,
    _formatSecondaryMessageWithUserName,
  ]);

  const [addUsersToWorkspace, { isLoading }] =
    useAddUsersToWorkspacesMutation();

  const handleSave = async () => {
    const response = await addUsersToWorkspace({
      workspace_ids: workspaceIds,
      user_ids: itemsNotInWorkspace.map((item) => item.id),
      role: 'user',
    });

    if (response?.data?.success) {
      const newItems = localSelectedItems.map((item) => ({
        ...item,
        isNotInWorkspace: false,
      }));
      handleAddSuccessCallback(newItems);
    }
  };

  return {
    itemsNotInWorkspace,
    formattedInfoMessages,
    handleSave,
    isLoading,
  };
};

export default useAddToWorkspace;
