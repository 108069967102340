import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '../../../../../../redux/user/selectors';
import useMultiselect from '../../../../../../hooks/useMultiselect';
import { internalRoutePaths } from '../../../../../../config/config';
import useAuthorization from '../../../hooks/useAuthorization';

const useReasign = ({ item, featureKey } = {}) => {
  const loggedInUser = useSelector(getUser);
  const isCollabEnabled = loggedInUser?.client?.settings?.include_collab;
  const { canManageUsers } = useAuthorization(item);

  const { selectedItems } = useMultiselect(null, featureKey ?? 'issues');

  const _getWorkspace = (ids) => {
    if (item) {
      return item.workspace_short_info;
    }

    const [first, ...rest] = ids;
    const firstWorkspace = selectedItems[first].workspace_short_info;

    if (
      Object.values(selectedItems)
        .filter((item) => rest.includes(item.id))
        .every((item) => item.workspace_id === firstWorkspace.id)
    ) {
      return firstWorkspace;
    }
    return null;
  };

  const getMessageProps = (ids, singeWsMessageKey, multipleWsMessageKey) => {
    const workspace = _getWorkspace(ids);
    const { id, name } = workspace ?? {};

    return {
      id: name ? singeWsMessageKey : multipleWsMessageKey,
      values: {
        count: ids.length,
        ...(name
          ? {
              workspace_name: name,
              a: (chunks) => (
                <a
                  target="_blank"
                  href={internalRoutePaths.WORKSPACES_ID.replace(':id', id)}
                  rel="noopener noreferrer"
                >
                  {chunks}
                </a>
              ),
            }
          : {}),
      },
    };
  };

  const workspaceIds = useMemo(() => {
    return item?.workspace?.id
      ? [item.workspace.id]
      : selectedItems
      ? Object.values(selectedItems).map((item) => item.workspace_id)
      : [];
  }, [item?.workspace?.id, selectedItems]);

  const workspaceName = useMemo(() => {
    return (
      item?.workspace?.name ?? Object.values(selectedItems)[0]?.workspace?.name
    );
  }, [item?.workspace?.name, selectedItems]);

  const shouldShowTabs = useMemo(() => {
    return loggedInUser?.client?.id === loggedInUser?.main_client_id;
  }, [loggedInUser?.client?.id, loggedInUser?.main_client_id]);

  return {
    isCollabEnabled,
    getMessageProps,
    workspaceIds,
    workspaceName,
    canManageUsers: canManageUsers || !item,
    shouldShowTabs,
  };
};

export default useReasign;
